import { ConfigQueryParams, UserServerConfigs } from '../types/userServerConfigs';
import { createDataContext } from './Context';

const parseUsersResponse = (response: UserServerConfigs[]): UserServerConfigs[] => {
  return Array.isArray(response) ? response : []; 
};

export const { useDataContext: useUserConfigContext, DataProvider: UserConfigProvider } = createDataContext<UserServerConfigs, ConfigQueryParams>(
  'tripQueryAPI',
  '/users/v1/getserverconfigs',
  parseUsersResponse
);
