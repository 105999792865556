import { ColumnDef } from "@tanstack/react-table";
import { convertDateFormat } from "./formatDate";
import { convertToYesNo } from "./formatBoolean";
import { convertToDisplayLicensePlate } from "./formatLicensePlate";
type FieldLabels = {
  'trip_id': string;
  'trip_start_time': string;
  'vehicle_id': string;
  'transponder_id': string;
  'in_corridor': string;
  'total_owned_trips': string;
  'user_uuid': string;
  [key: string]: string; 
};

// Custom labels
const FIELD_LABELS: FieldLabels = {
  'trip_id': 'Trip ID',
  'trip_start_time': 'Start Time',
  'vehicle_id': 'Vehicle ID',
  'transponder_id': 'Transponder ID',
  'in_corridor': 'In Corridor',
  'total_owned_trips': 'Total Trips',
  'user_uuid': 'UUID',
  'user_id': 'User ID',
  'count_of_trip': 'Violations',
  'CountOftrip_id': 'Violations',
  'highway_trips': 'Highway Trips',
  'completed_trips': 'Completed Trips'
};

// Define field type patterns for special rendering
const DATE_PATTERNS = ['_time', '_date', 'timestamp', 'reported_time', 'created_on', 'modified_on'];
export const BOOLEAN_PATTERNS = ['is_', 'has_', '_flag', 'valid', 'in_corridor', 'auto_close' , 'no_gps'];

// Convert snake_case to Title Case
function formatFieldName(field: string): string {
  return field
    .split(/[_\s]/) // Split by both underscore and space
    .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
    .join(' ');
}

// Determine if a field should be rendered as a date
function isDateField(field: string): boolean {
  return DATE_PATTERNS.some(pattern => field.toLowerCase().includes(pattern));
}

// Determine if a field should be rendered as a boolean
function isBooleanField(field: string): boolean {
  return BOOLEAN_PATTERNS.some(pattern => field.toLowerCase().includes(pattern));
}

// Generate render function based on field type
export function getRenderFunction(field: string): ((value: string) => string | null) {
  if (isDateField(field)) {
    return (value: string) => value ? convertDateFormat(value) : 'N/A'; 
  }

  if (isBooleanField(field)) {
    return (value: string) => {
      return convertToYesNo(value);
    };
  }

  if (field.includes('license_plate')) {
    return (value: string) => convertToDisplayLicensePlate(value); 
  }

  // Default case: return the value as-is
  return (value: string) => value || 'N/A'; // Return plain value or 'N/A' if value is missing
}

export function generateColumns<T>(data: Record<string, any>[]): ColumnDef<T, any>[] {
  if (!data || data.length === 0) return [];

  const sampleData = data[0];

  return Object.keys(sampleData).map((field) => {

    const renderFn = getRenderFunction(field);
    
    return {
      accessorKey: field, // Maps to accessorKey in ColumnDef
      header: FIELD_LABELS[field] || formatFieldName(field), // Maps to header in ColumnDef
      cell: renderFn ? ({ cell }) => renderFn(cell.getValue() as string) : undefined, // Maps to custom render function, if any
      enableSorting: true, // Assuming sortable corresponds to enableSorting in ColumnDef
      enableHiding: true,
    };
  });
}

// Filter out unwanted columns
export function filterColumns<T>(columns: ColumnDef<T, any>[], excludeFields: string[] = []): ColumnDef<T, any>[] {
  return columns.filter(col => 
    !excludeFields.includes((col as any).accessorKey || '') 
  );
}