import { Alert, Text, Button, Flex } from '@aws-amplify/ui-react';

type ActionHandler<T> = (data?: T) => void;

interface AlertViewProps<T> {
  message: string;
  onDismiss?: () => void;
  onAction?: ActionHandler<T>;
  actionData?: T;
  buttonTitle?: string
}

export const AlertView = <T,>({
  message,
  onDismiss,
  onAction,
  actionData,
  buttonTitle
}: AlertViewProps<T>): JSX.Element => {
  return (
    <Alert 
      variation="error"
      isDismissible={true}
      onDismiss={onDismiss}
      hasIcon={true}
    >
      <Flex 
        direction="row"
        justifyContent="space-between"
        alignItems="center"
      >
        <Text data-testid="alert-message">{message}</Text>
        {onAction && (
          <Button
            onClick={() => onAction(actionData)}
          >
            <Text>{buttonTitle ?? ""}</Text>
          </Button>
        )}
      </Flex>
    </Alert>
  );
};