import { useEffect, useState } from 'react'
import { useNavigate, useParams } from "react-router-dom"
import { UserProfile } from "../types/user"
import { useUserContext } from "../contexts/UserContext"
import { useResetContext } from '../contexts/ResetContext'
import { useToast } from '../hooks/use-toast'
import { Button } from "./ui/button"
import { Card, CardContent, CardHeader, CardTitle } from "./ui/card"
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from "./ui/table"
import { Input } from "./ui/input"
import { ArrowLeft, Loader2, RefreshCcw } from 'lucide-react'
import { useUserConfigContext } from '../contexts/UserConfigContext'
import { AlertDialog, AlertDialogCancel, AlertDialogFooter, AlertDialogDescription, AlertDialogContent, AlertDialogHeader, AlertDialogTitle, AlertDialogAction } from './ui/alert-dialog'
import { useTrackNavigation } from '../hooks/useTrackNavigation'
import { convertDateFormat } from '../utils/formatDate'
import { getUser } from '../utils/auth'

const UserDetailComponent = (): JSX.Element => {
    const navigate = useNavigate()
    const { toast } = useToast()
    const { user_uuid } = useParams<{ user_uuid: string }>()
    const { data } = useUserContext()
    const user: UserProfile | undefined = data.find((user: UserProfile) => user.user_uuid === user_uuid)
    const [date, setDate] = useState<string>(new Date().toLocaleDateString('en-CA'))
    const { fetchData: resetDate, error, loading } = useResetContext()
    const { data: userConfig, loading: userConfigLoading, error: userConfigError, fetchData: fetchUserConfig } = useUserConfigContext()
    const [isResetDialogOpen, setIsResetDialogOpen] = useState(false)
    const [agentDetails, setAgentDetails] = useState<{ userId: string, loginId: string } | null>(null)
    useTrackNavigation()

    useEffect(() => {
        const getUserDetails = async () => {
            const { userId, signInDetails } = await getUser()
            setAgentDetails({ userId, loginId: signInDetails?.loginId || '' })
        }

        if (user) {
            fetchUserConfig({ user_id: user.user_id })
            getUserDetails()
        }
    }, [user])

    if (!user) {
        return <div className="p-4">No user data found</div>
    }

    const handleBack = (): void => {
        navigate('/userProfiles')
    }

    const handleRefresh = async (user_id: string): Promise<void> => {
        await fetchUserConfig({ user_id: user_id })
        if (userConfigError) {
            toast({
                title: 'Refresh failed',
                description: "Failed to refresh data. Please try again later.",
                variant: 'destructive',
            })
        }
    }

    const handleReset = async (): Promise<void> => {
        if (!agentDetails || !agentDetails.userId || !agentDetails.loginId || !date || !user.user_id) {
            toast({
                title: 'Missing required fields',
                description: "Please try signing in again.",
                variant: 'destructive',
            })
            return
        }

        await resetDate({ user_id: user.user_id, loginId: agentDetails?.loginId || '', date })
        if (error) {
            toast({
                title: 'Reset failed',
                description: "Failed to reset date. Please try again later.",
                variant: 'destructive',
            })
        } else {
            toast({
                title: 'Reset successful',
                description: 'User date has been reset',
            })
        }
    }

    const handleAlertDialog = () => {
        return (
            <AlertDialog 
                open={isResetDialogOpen} 
                onOpenChange={setIsResetDialogOpen}
            >
                <AlertDialogContent className="bg-white">
                    <AlertDialogHeader>
                        <AlertDialogTitle>Are you sure you want to reset the look-back date?</AlertDialogTitle>
                        <AlertDialogDescription>
                            This action cannot be undone.
                        </AlertDialogDescription>
                    </AlertDialogHeader>
                    <AlertDialogFooter>
                        <AlertDialogCancel>Cancel</AlertDialogCancel>
                        <AlertDialogAction onClick={handleReset}>Continue</AlertDialogAction>
                    </AlertDialogFooter>
                </AlertDialogContent>
            </AlertDialog>
        )
    }

    return (
        <div className="p-4 space-y-4">
            {handleAlertDialog()}
            <Card className="w-full max-w-sm bg-white">
                <CardHeader>
                    <CardTitle>Reset Date</CardTitle>
                </CardHeader>
                <CardContent>
                    <div className="flex space-x-2">
                        <Input
                            type="date"
                            onChange={(e) => setDate(e.target.value)}
                            value={date}
                            className="flex-grow"
                        />
                        <Button
                            onClick={() => setIsResetDialogOpen(true)}
                            className="bg-green-600 hover:bg-green-700 text-white"
                            disabled={loading || !date}
                        >
                            {loading ? <Loader2 className="mr-2 h-4 w-4 animate-spin" /> : 'Reset'}
                        </Button>
                    </div>
                </CardContent>
            </Card>

            <Card className="bg-white">
                <CardHeader className="flex flex-row items-center justify-between space-y-0 pb-2">
                    <Button onClick={handleBack} variant="outline" size="sm">
                        <ArrowLeft className="mr-2 h-4 w-4" /> Back to List
                    </Button>
                    <CardTitle className="text-2xl font-bold">User Details</CardTitle>
                </CardHeader>
                <CardContent className="pt-5">
                    <Table>
                        <TableBody>
                            <TableRow>
                                <TableCell className="font-medium">User UUID</TableCell>
                                <TableCell>{user.user_uuid}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell className="font-medium">Created On</TableCell>
                                <TableCell>{convertDateFormat(user.created_on || '')}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell className="font-medium">Modified On</TableCell>
                                <TableCell>{convertDateFormat(user.modified_on || '')}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell className="font-medium">In Corridor</TableCell>
                                <TableCell>{user["In Corridor"]}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell className="font-medium">Completed Trips</TableCell>
                                <TableCell>{user["Completed Trips"]}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell className="font-medium">Violations</TableCell>
                                <TableCell>{user["Violations"]}</TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </CardContent>
            </Card>

            <Card className="bg-white">
            <CardHeader className="flex flex-row items-center justify-between space-y-0 pb-2">
                    <Button onClick={() => handleRefresh(user.user_id)} variant="outline" size="sm">
                        <RefreshCcw className="mr-2 h-4 w-4" /> Refresh
                    </Button>
                    <CardTitle className="text-2xl font-bold">Look-Back Dates</CardTitle>
                </CardHeader>
                <CardContent>
                    {userConfigLoading ? (
                        <div className="flex justify-center items-center h-15">
                            <Loader2 className="h-8 w-8 animate-spin" size={15} />
                        </div>
                    ) : userConfig && userConfig.length > 0 ? (
                        <Table>
                            <TableHeader>
                                <TableRow>
                                    <TableHead>Look-Back Date</TableHead>
                                    <TableHead>Modified Date</TableHead>
                                    <TableHead>Modified By</TableHead>
                                </TableRow>
                            </TableHeader>
                            <TableBody>
                                {userConfig.map((config, index) => (
                                    <TableRow key={index}>
                                        <TableCell>
                                            {convertDateFormat(config.export_text || '')}
                                        </TableCell>
                                        <TableCell>
                                            {convertDateFormat(config.modified_date || '')}
                                        </TableCell>
                                        <TableCell>
                                            {config.modified_by}
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    ) : (
                        <div>No user data available</div>
                    )}
                </CardContent>
            </Card>
        </div>
    )
}

export default UserDetailComponent