import { AuthUser, fetchAuthSession, getCurrentUser } from 'aws-amplify/auth';

export async function getValidAuthToken() {
    try {
        const session = await fetchAuthSession();
        if (!session || !session.tokens || !session.tokens.idToken) {
            // No valid session, return null instead of throwing an error
            return null;
        }
        return session.tokens.idToken.toString();
    } catch (error) {
        // Only log if it's an unexpected error, not just a missing session
        if (error instanceof Error && error.name !== 'NoSessionError') {
            console.error('Error refreshing auth token:', error);
            // Don't use logger here to avoid potential circular dependency
        }
        return null;
    }
}

// Check if user has admin access
// A temporary solution 
export const hasAdminAccess = (user: AuthUser): boolean => {
    if (!user?.signInDetails?.loginId) return false;

    const adminUsers = process.env.REACT_APP_ADMIN_USERS?.split(',') || [];
    return adminUsers.includes(user.signInDetails.loginId);
};

export async function getUser() {
    const { username, userId, signInDetails } = await getCurrentUser();
    return { username, userId, signInDetails }
}