import React, { createContext, useContext, ReactNode } from 'react'
import { useQuery, QueryClient, QueryClientProvider, UseQueryResult, QueryKey } from '@tanstack/react-query'
import { get } from 'aws-amplify/api'
import { getValidAuthToken } from '../utils/auth'
import logger from '../services/logger'

interface ApiContextType {
    useApiQuery: <T>(apiName: string, path: string, params: Record<string, string>, enabled: boolean) => UseQueryResult<T, Error>
}

const ApiContext = createContext<ApiContextType | undefined>(undefined)

export const useApi = () => {
    const context = useContext(ApiContext)
    if (!context) {
        throw new Error('useApi must be used within an ApiProvider')
    }
    return context
}

const fetchData = async <T,>(queryKey: QueryKey): Promise<T> => {
    const [apiName, path, params] = queryKey as [string, string, Record<string, string>]
    try {
        const authToken = await getValidAuthToken()
        const restOperation = get({
            apiName,
            path,
            options: {
                headers: { Authorization: authToken || '' },
                queryParams: params
            }
        })

        const response = await restOperation.response
        if (response.body instanceof ReadableStream) {
            const reader = response.body.getReader()
            const decoder = new TextDecoder()
            let result = ''
            while (true) {
                const { done, value } = await reader.read()
                if (done) break
                result += decoder.decode(value)
            }
            return JSON.parse(result)
        } else {
            return response as T
        }
    } catch (err) {
        await logger.log(`Error fetching data: ${err}`, { source: { file: __filename } })
        throw new Error('Failed to fetch data')
    }
}

export const ApiProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
    const queryClient = new QueryClient()

    const useApiQuery = <T,>(apiName: string, path: string, params: Record<string, string>, enabled: boolean): UseQueryResult<T, Error> => {
        return useQuery<T, Error>([apiName, path, params], () => fetchData<T>([apiName, path, params]), {
            enabled: enabled,
            keepPreviousData: true,
            staleTime: 5 * 60 * 1000, // 5 minutes
        })
    }

    const contextValue: ApiContextType = {
        useApiQuery
    }

    return (
        <QueryClientProvider client={queryClient}>
            <ApiContext.Provider value={contextValue}>
                {children}
            </ApiContext.Provider>
        </QueryClientProvider>
    )
}