import { useEffect, useRef } from 'react';
import { createEmbeddingContext, EmbeddableDashboard } from 'amazon-quicksight-embedding-sdk';
import { useQuickSightContext } from '../contexts/QuickSightContext';
import logger from '../services/logger';
import { AlertView } from './common/Alert';
import { QuickSightDashboardSkeleton } from './QuickSightSkeleton';
import { useTrackNavigation } from '../hooks/useTrackNavigation';

export default function QuickSightDashboard(): JSX.Element {
    const { embedUrl, loading, error, getQuickSightEmbedUrl } = useQuickSightContext();
    const dashboardContainerRef = useRef<HTMLDivElement>(null);
    useTrackNavigation()

    useEffect(() => {
        getQuickSightEmbedUrl();
    }, [getQuickSightEmbedUrl]);

    useEffect(() => {
        let embeddedDashboard: EmbeddableDashboard | undefined;

        const embedDashboard = async () => {
            if (embedUrl && dashboardContainerRef.current) {
                try {
                    const embeddingContext = await createEmbeddingContext();

                    const frameOptions = {
                        url: embedUrl,
                        container: dashboardContainerRef.current,
                        scrolling: "no",
                        height: "850px",
                        width: "100%",
                    };

                    embeddedDashboard = await embeddingContext.embedDashboard(frameOptions);

                    embeddedDashboard.on("error", (event: unknown) => {
                        logger.log(`Dashboard embedding error: ${event}`, { source: { file: __filename } });
                    });
                } catch (error) {
                    // logger.log(`Error embedding QuickSight dashboard: ${error}`, { source: { file: __filename } });
                }
            }
        };

        embedDashboard();

        return () => {
            if (embeddedDashboard && typeof embeddedDashboard.dispose === 'function') {
                try {
                    embeddedDashboard.dispose();
                } catch (error) {
                    console.warn('Error disposing QuickSight dashboard:', error);
                }
            }
        };
    }, [embedUrl]);

    return (
        <>
            {error ? (
                <AlertView message="An error has occurred." />
            ) : loading ? (
                <QuickSightDashboardSkeleton />
            ) : (
                <div
                    ref={dashboardContainerRef}
                    style={{
                        width: "100%",
                        height: "100%",
                        top: 0,
                        left: 0,
                    }}
                ></div>
            )}
        </>
    );
};
