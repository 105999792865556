import { useEffect, useRef, useState } from 'react'
import { useTripQuery, useTripCountQuery, useDownloadableTripsQuery } from '../contexts/TripContext'
import { Trip, TripQueryState } from '../types/trip'
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from "./ui/table"
import {
    Pagination,
    PaginationContent,
    PaginationEllipsis,
    PaginationItem,
    PaginationNext,
    PaginationPrevious,
} from "./ui/pagination"
import { usePersistedState } from '../hooks/usePersistedState'
import { formatDate } from '../utils/formatDate'
import { useNavigate } from 'react-router-dom'
import { useToast } from '../hooks/use-toast'
import { useTrackNavigation } from '../hooks/useTrackNavigation'
import { Card, CardContent, CardHeader, CardTitle } from './ui/card'
import { Button } from './ui/button'
import { Spinner } from './ui/spinner'
import { Download, RefreshCw } from 'lucide-react'
import { Input } from './ui/input'
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from './ui/select'
import { useCSVDownload } from '../hooks/useCSVDownload'
import { AlertView } from './common/Alert'
import { DataTableSkeleton } from './TableSkeleton'
import { convertDateFormat } from '../utils/formatDate'
import { convertToYesNo } from '../utils/formatBoolean'
import { convertToDisplayLicensePlate } from '../utils/formatLicensePlate'
import { sanitize, validateEmail } from '../utils/sanitizeInput'

const TripQueryComponent = (): JSX.Element => {
    const navigate = useNavigate()
    const { toast } = useToast()
    const { isDownloading, handleDownload } = useCSVDownload<Trip>()
    const hasMounted = useRef(false)
    const [emailFilterError, setEmailFilterError] = useState<string | null>(null)
    const [tripIdFilterError, setTripIdFilterError] = useState<string | null>(null)
    const [licensePlateError, setLicensePlateError] = useState<string | null>(null)
    const [sortDirection, setSortDirection] = useState<'asc' | 'desc' | null>(null);
    useTrackNavigation()

    const getDefaultState = (): TripQueryState => {
        const defaultEndDate = new Date()
        const defaultStartDate = new Date(defaultEndDate)
        defaultStartDate.setDate(defaultStartDate.getDate() - 2)

        return {
            currentPage: 1,
            itemsPerPage: 10,
            phoneNumber: '',
            email: '',
            autoCloseFilter: 'all',
            startDate: formatDate(defaultStartDate),
            endDate: formatDate(defaultEndDate),
            licensePlate: '',
            grantedFilter: 'all',
            validFilter: 'all',
            tableFilter: '',
            tripIdFilter: ''
        }
    }

    const [queryState, setQueryState] = usePersistedState<TripQueryState>({
        storageKey: 'tripQueryState',
        defaultValue: getDefaultState()
    })

    const {
        isError,
        data,
        isFetching,
        refetch
    } = useTripQuery(queryState, false)

    const {
        data: tripCountData,
        refetch: tripCountRefetch
    } = useTripCountQuery(queryState, false)

    const {
        isError: downloadableTripsIsError,
        data: downloadableTripsData,
        isFetching: downloadableTripsIsFetching,
        refetch: downloadableTripsRefetch
    } = useDownloadableTripsQuery(queryState, false)

    const trips = data || []
    const totalPages = Math.ceil((tripCountData?.[0]?.totalCount || 0) / queryState.itemsPerPage)

    useEffect(() => {
        if (hasMounted.current) {
            refetch()
            tripCountRefetch()
        }
        hasMounted.current = true
    }, [queryState.currentPage])

    const handlePageChange = async (newPage: number) => {
        if (newPage < 1 || newPage > totalPages) return; // Prevent invalid page changes
        setQueryState(prev => ({ ...prev, currentPage: newPage }))
    }

    const handleItemsPerPageChange = (newItemsPerPage: number) => {
        setQueryState(prev => ({ ...prev, itemsPerPage: newItemsPerPage, currentPage: 1 }))
        refetch()
        tripCountRefetch()
    }

    const handleLicensePlateChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
        // Only allow alphanumeric characters (letters and numbers)
        const value = e.target.value.replace(/[^a-zA-Z0-9]/g, '').toUpperCase();

        if (value.length > 10) {
            setLicensePlateError('Invalid license plate');
        } else {
            setLicensePlateError(null);
        }

        setQueryState(prev => ({
            ...prev,
            licensePlate: value.slice(0, 10)
        }));
    }

    const handleTripIdFilterChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
        const value = sanitize(e.target.value)
        if (value.length === 0) {
            setTripIdFilterError(null)
            setQueryState(prev => ({ ...prev, tripIdFilter: value }))
        } else if (value.length < 24) {
            setTripIdFilterError('Trip ID must be 24 characters long')
            setQueryState(prev => ({ ...prev, tripIdFilter: value }))
        } else {
            setTripIdFilterError(null)
            setQueryState(prev => ({ ...prev, tripIdFilter: value }))
        }
    }

    const resetToDefaults = () => {
        const defaults = getDefaultState()
        setQueryState(defaults)
        setTripIdFilterError(null)
        setEmailFilterError(null)
    }

    const handlePhoneNumberChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
        const value = e.target.value.replace(/\D/g, '')
        setQueryState(prev => ({ ...prev, phoneNumber: value.slice(0, 10) }))
    }

    const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
        const value = sanitize(e.target.value)
        if (value) {
            setEmailFilterError(null)
        }

        if (value && !validateEmail(value)) {
            setEmailFilterError('Please enter a valid email address')
        } else {
            setEmailFilterError(null)
        }

        setQueryState(prev => ({ ...prev, email: value }))
    }

    const handleFindClick = (): void => {
        setQueryState(prev => ({ ...prev, currentPage: 1 }))
        refetch()
        tripCountRefetch()
    }

    const handleDownloadCSV = async () => {
        await downloadableTripsRefetch()

        if (downloadableTripsData?.length === 0) {
            toast({
                title: 'No trips found',
                description: 'No trips found for the selected filters',
                variant: 'destructive',
            })
            return
        }

        if (downloadableTripsIsError) {
            toast({
                title: 'Error',
                description: 'An error has occurred',
                variant: 'destructive',
            })
            return
        }

        handleDownload({
            getData: () => downloadableTripsData || [],
            filename: 'trip_data.csv',
            sanitizeData: (trips) => trips.map(({ user_id, phone_number, email, ...rest }) => rest)
        })
    }

    const handleRowClick = (trip: Trip) => {
        navigate(`/trips/${trip.trip_id}`, {
            state: {
                tripData: trip,
            }
        });
    };

    const handleEndDateSort = () => {
        //const newDirection = sortDirection === 'asc' ? 'desc' : 'asc';
        //setSortDirection(newDirection);
        //
        //const sortedTrips = [...trips].sort((a, b) => {
        //    const dateA = new Date(a.end_time).getTime();
        //    const dateB = new Date(b.end_time).getTime();
        //    return newDirection === 'asc' ? dateA - dateB : dateB - dateA;
        //});
        //
        // If you're using state to store trips, update it here
        // Otherwise, you might need to modify your data fetching logic
    };

    return (
        <div className="top-margin p-4">
            <div className="flex flex-col md:flex-row gap-5 mb-2">
                <Card className="flex-1 md:max-w-[60rem] bg-white">
                    <CardHeader className="pb-2">
                        <CardTitle className="flex justify-between items-center">
                            <span>Filters</span>
                            <div className="text-right">
                                <p className="text-sm font-medium text-muted-foreground">Trip Count</p>
                                <div className="bg-primary/10 px-2 py-1 rounded-md">
                                    <p className="text-lg font-semibold text-primary" data-testid="trip-count">
                                        {tripCountData?.[0]?.totalCount ?? 0}
                                    </p>
                                </div>
                            </div>
                        </CardTitle>
                    </CardHeader>
                    <CardContent className="pt-0 pb-4">
                        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-4">
                            <div className="space-y-1">
                                <label htmlFor="phoneNumber" className="text-sm font-medium">Phone Number</label>
                                <Input
                                    id="phoneNumber"
                                    className="w-full"
                                    type="tel"
                                    placeholder="Enter phone #"
                                    value={queryState.phoneNumber}
                                    onChange={handlePhoneNumberChange}
                                />
                            </div>
                            <div className="space-y-1">
                                <label htmlFor="email" className="text-sm font-medium">Email</label>
                                <Input
                                    id="email"
                                    className="w-full"
                                    type="email"
                                    placeholder="Enter email"
                                    value={queryState.email}
                                    onChange={handleEmailChange}
                                />
                                {emailFilterError && <p className="text-red-500 text-sm">{emailFilterError}</p>}
                            </div>
                            <div className="space-y-1">
                                <label htmlFor="licensePlate" className="text-sm font-medium">License Plate</label>
                                <Input
                                    id="licensePlate"
                                    className="w-full"
                                    placeholder="Enter license plate"
                                    value={queryState.licensePlate}
                                    onChange={handleLicensePlateChange}
                                    maxLength={10}
                                />
                                {licensePlateError && <p className="text-red-500 text-sm">{licensePlateError}</p>}
                            </div>
                            <div className="space-y-1">
                                <label htmlFor="tripIdFilter" className="text-sm font-medium">Trip ID</label>
                                <Input
                                    id="tripIdFilter"
                                    className="w-full"
                                    placeholder="Enter Trip ID"
                                    value={queryState.tripIdFilter}
                                    onChange={handleTripIdFilterChange}
                                    maxLength={24}
                                />
                                {tripIdFilterError && <p className="text-red-500 text-sm">{tripIdFilterError}</p>}
                            </div>
                        </div>
                        <div className="flex gap-4 mt-4">
                            <div className="space-y-1">
                                <label htmlFor="autoClose" className="text-sm font-medium">Auto-Close</label>
                                <Select
                                    value={queryState.autoCloseFilter}
                                    onValueChange={(value) => setQueryState({ ...queryState, autoCloseFilter: value })}
                                >
                                    <SelectTrigger id="autoClose" className="w-full md:w-20">
                                        <SelectValue placeholder="All" />
                                    </SelectTrigger>
                                    <SelectContent className="bg-white">
                                        <SelectItem value="all" className="hover:bg-slate-100 cursor-pointer">All</SelectItem>
                                        <SelectItem value="true" className="hover:bg-slate-100 cursor-pointer">True</SelectItem>
                                        <SelectItem value="false" className="hover:bg-slate-100 cursor-pointer">False</SelectItem>
                                    </SelectContent>
                                </Select>
                            </div>
                            <div className="space-y-1">
                                <label htmlFor="inCorridor" className="text-sm font-medium">In Corridor</label>
                                <Select
                                    value={queryState.grantedFilter}
                                    onValueChange={(value) => setQueryState({ ...queryState, grantedFilter: value })}
                                >
                                    <SelectTrigger id="inCorridor" className="w-full md:w-20">
                                        <SelectValue placeholder="All" />
                                    </SelectTrigger>
                                    <SelectContent className="bg-white">
                                        <SelectItem value="all" className="hover:bg-slate-100 cursor-pointer">All</SelectItem>
                                        <SelectItem value="1" className="hover:bg-slate-100 cursor-pointer">Yes</SelectItem>
                                        <SelectItem value="0" className="hover:bg-slate-100 cursor-pointer">No</SelectItem>
                                    </SelectContent>
                                </Select>
                            </div>
                            <div className="space-y-1">
                                <label htmlFor="valid" className="text-sm font-medium">Valid</label>
                                <Select
                                    value={queryState.validFilter}
                                    onValueChange={(value) => setQueryState({ ...queryState, validFilter: value })}
                                >
                                    <SelectTrigger id="valid" className="w-full md:w-20">
                                        <SelectValue placeholder="All" />
                                    </SelectTrigger>
                                    <SelectContent className="bg-white">
                                        <SelectItem value="all" className="hover:bg-slate-100 cursor-pointer">All</SelectItem>
                                        <SelectItem value="1" className="hover:bg-slate-100 cursor-pointer">Yes</SelectItem>
                                        <SelectItem value="0" className="hover:bg-slate-100 cursor-pointer">No</SelectItem>
                                    </SelectContent>
                                </Select>
                            </div>
                        </div>
                    </CardContent>
                </Card>

                <Card className="flex-1 md:max-w-md bg-white flex flex-col">
                    <CardHeader className="pb-2">
                        <CardTitle>Date Range</CardTitle>
                    </CardHeader>
                    <CardContent className="pt-0 flex flex-col justify-end flex-1">
                        <div className="space-y-9">
                            <div className="grid grid-cols-2 gap-4">
                                <div className="space-y-1">
                                    <label htmlFor="startDate" className="text-sm font-medium">
                                        Start Date
                                    </label>
                                    <Input
                                        value={queryState.startDate}
                                        id="startDate"
                                        type="date"
                                        className="w-full"
                                        onChange={(e) => setQueryState({ ...queryState, startDate: e.target.value })}
                                    />
                                </div>
                                <div className="space-y-1">
                                    <label htmlFor="endDate" className="text-sm font-medium">
                                        End Date
                                    </label>
                                    <Input
                                        value={queryState.endDate}
                                        id="endDate"
                                        type="date"
                                        className="w-full"
                                        onChange={(e) => setQueryState({ ...queryState, endDate: e.target.value })}
                                    />
                                </div>
                            </div>
                            <Button
                                onClick={handleFindClick}
                                className="w-full bg-green-600 hover:bg-green-700 text-white"
                            >
                                Find
                            </Button>
                        </div>
                    </CardContent>
                </Card>

                <div className="flex flex-col gap-2 md:justify-end self-end">
                    <Button
                        onClick={resetToDefaults}
                        variant="outline"
                        className="w-full md:w-auto bg-secondary hover:bg-secondary/90 text-secondary-foreground bg-green-600 text-white hover:bg-green-700"
                    >
                        <RefreshCw className="w-4 h-4 mr-2" />
                        Reset
                    </Button>
                    <Button
                        onClick={handleDownloadCSV}
                        variant="outline"
                        className="w-full md:w-auto bg-primary hover:bg-primary/90 text-primary-foreground bg-green-600 text-white hover:bg-green-700"
                        disabled={
                            isDownloading
                            || downloadableTripsIsFetching
                            || downloadableTripsData?.length === 0
                            || trips.length === 0
                        }
                        data-testid="download-csv-button"
                    >
                        {downloadableTripsIsFetching ? (
                            <>
                                <Spinner size="small" className="mr-2" />
                                Downloading...
                            </>
                        ) : (
                            <>
                                <Download className="w-4 h-4 mr-2" />
                                Download CSV
                            </>
                        )}
                    </Button>
                </div>
            </div>

            <div className="mt-4 p-0">
                {isError ? (
                    <AlertView
                        message="An error has occurred"
                        onAction={handleFindClick}
                        buttonTitle="Retry"
                    />
                ) : isFetching ? (
                    <DataTableSkeleton rows={queryState.itemsPerPage} columns={10} />
                ) : (
                    <Card className="flex-1 bg-white pt-6">
                        {/* <CardHeader className="flex flex-col justify-start space-y-0 pb-2 pt-0">
                            <Input
                                value={queryState.tripIdFilter}
                                id="tripIdFilter"
                                type="text"
                                className="w-[28%]"
                                onChange={handleTripIdFilterChange}
                                placeholder="Filter by trip ID"
                            />
                            {tripIdFilterError && <p className="text-red-500 text-sm">{tripIdFilterError}</p>}
                        </CardHeader> */}
                        <CardContent className="pt-0 pb-4">
                            <Table>
                                <TableHeader>
                                    <TableRow>
                                        <TableHead>Trip ID</TableHead>
                                        <TableHead>Start Date</TableHead>
                                        <TableHead
                                            onClick={handleEndDateSort}
                                            className="cursor-pointer hover:bg-slate-100"
                                        >
                                            End Date {sortDirection === 'asc' ? '↑' : sortDirection === 'desc' ? '↓' : ''}
                                        </TableHead>
                                        <TableHead>Occupancy</TableHead>
                                        <TableHead>In Corridor</TableHead>
                                        <TableHead>Valid</TableHead>
                                        <TableHead>Transponder ID</TableHead>
                                        <TableHead>No GPS</TableHead>
                                        <TableHead>License Plate</TableHead>
                                    </TableRow>
                                </TableHeader>
                                <TableBody>
                                    {trips.map((trip: Trip) => (
                                        <TableRow
                                            key={trip.trip_id}
                                            onClick={() => handleRowClick(trip)}
                                            className="cursor-pointer hover:bg-slate-100"
                                            data-testid="trip-row"
                                        >
                                            <TableCell data-testid="trip_id">{trip.trip_id}</TableCell>
                                            <TableCell data-testid="trip_start_time">{convertDateFormat(trip.trip_start_time)}</TableCell>
                                            <TableCell data-testid="end_time">{convertDateFormat(trip.end_time)}</TableCell>
                                            <TableCell data-testid="occupancy">{trip.occupancy}</TableCell>
                                            <TableCell data-testid="in_corridor">{convertToYesNo(trip.in_corridor)}</TableCell>
                                            <TableCell data-testid="valid">{convertToYesNo(trip.valid)}</TableCell>
                                            <TableCell data-testid="transponder_id">{trip.transponder_id}</TableCell>
                                            <TableCell data-testid="no_gps">{convertToYesNo(trip.no_gps)}</TableCell>
                                            <TableCell data-testid="license_plate">{convertToDisplayLicensePlate(trip.license_plate)}</TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                            <div className="flex items-center justify-between w-full mt-4">
                                <div>
                                    <Select
                                        value={`${queryState.itemsPerPage}`}
                                        onValueChange={(value) => handleItemsPerPageChange(Number(value))}
                                    >
                                        <SelectTrigger>
                                            <SelectValue />
                                        </SelectTrigger>
                                        <SelectContent className="bg-white">
                                            {[10, 20].map((pageSize) => (
                                                <SelectItem
                                                    key={pageSize}
                                                    value={`${pageSize}`}
                                                    className="hover:bg-slate-100 cursor-pointer"
                                                >
                                                    {pageSize} rows
                                                </SelectItem>
                                            ))}
                                        </SelectContent>
                                    </Select>
                                </div>
                                <Pagination className="w-full justify-end">
                                <PaginationContent className="justify-end">
                                        <PaginationItem>
                                            <PaginationPrevious
                                                href="#"
                                                onClick={() => handlePageChange(Math.max(1, queryState.currentPage - 1))}
                                                className={queryState.currentPage <= 1 ? 'cursor-not-allowed opacity-50' : ''}
                                            />
                                        </PaginationItem>
                                        <PaginationEllipsis />
                                        <PaginationItem>
                                            <PaginationNext
                                                href="#"
                                                onClick={() => handlePageChange(queryState.currentPage + 1)}
                                                className={queryState.currentPage >= totalPages ? 'cursor-not-allowed opacity-50' : ''}
                                            />
                                        </PaginationItem>
                                    </PaginationContent>
                                </Pagination>
                            </div>
                        </CardContent>
                    </Card>
                )}
            </div>
        </div>
    )
}

export default TripQueryComponent
