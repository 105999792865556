import { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { Button } from "./ui/button"
import { Table, TableBody, TableCell, TableRow } from "./ui/table"
import { Card, CardContent, CardHeader, CardTitle, CardFooter } from "./ui/card"
import { ArrowLeft, FileX2 } from 'lucide-react'
import { useTrackNavigation } from '../hooks/useTrackNavigation'
import { convertDateFormat } from '../utils/formatDate'
import { convertToYesNo } from '../utils/formatBoolean'
import { convertToDisplayLicensePlate } from '../utils/formatLicensePlate'
import { Trip } from '../types/trip'

const TripDetailComponent = (): JSX.Element => {
  const location = useLocation()
  const navigate = useNavigate()
  const [trip, setTrip] = useState<Trip | undefined>(location.state?.tripData)
  useTrackNavigation()

  useEffect(() => {
    if (trip) {
      localStorage.setItem('tripData', JSON.stringify(trip))
    } else {
      const tripData = localStorage.getItem('tripData')
      if (!tripData) {
        handleBack()
      } else {
        const parsedTripData = tripData ? JSON.parse(tripData) : null
        const pathname = location.pathname
        const lastComponent = pathname.split('/').filter(Boolean).pop();

        if (lastComponent !== parsedTripData?.trip_id) {
          // A user types in the URL with a different trip_id manually than the stored trip_id shouldn't be displayed
          handleBack()
        } else  {
          setTrip(JSON.parse(tripData))
        }
      }
    }
  }, [trip])

  const handleBack = (): void => {
    navigate('/trips')
  }

  if (!trip) {
    return (
      <div className="flex items-center justify-center min-h-screen bg-gray-100">
        <Card className="w-full max-w-md bg-white shadow-lg">
          <CardHeader className="text-center">
            <div className="mx-auto my-6 w-20 h-20 rounded-full bg-red-100 flex items-center justify-center">
              <FileX2 className="h-10 w-10 text-red-600" />
            </div>
            <CardTitle className="text-2xl font-bold text-gray-900">Trip Not Found</CardTitle>
          </CardHeader>
          <CardContent>
            <p className="text-center text-gray-600">
              We're sorry, but unable to display the trip details.
            </p>
          </CardContent>
          <CardFooter className="flex justify-center">
            <Button
              onClick={handleBack}
              variant="default"
              className="w-full sm:w-auto"
            >
              <ArrowLeft className="mr-2 h-4 w-4" /> Back to Trip List
            </Button>
          </CardFooter>
        </Card>
      </div>
    )
  }

  return (
    <div className="p-4">
      <Card className="bg-white">
        <CardHeader className="flex flex-row items-center justify-between space-y-0 pb-2">
          <Button onClick={handleBack} variant="outline" size="sm">
            <ArrowLeft className="mr-2 h-4 w-4" /> Back to List
          </Button>
          <CardTitle className="text-2xl font-bold">Trip Details</CardTitle>
        </CardHeader>
        <CardContent className="pt-5">
          <Table>
            <TableBody>
              <TableRow>
                <TableCell className="font-medium">User UUID</TableCell>
                <TableCell data-testid={'user_uuid'}>{trip.user_uuid}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell className="font-medium">Trip ID</TableCell>
                <TableCell data-testid={'trip-id'}>{trip.trip_id}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell className="font-medium">Vehicle ID</TableCell>
                <TableCell data-testid={'vehicle_id'}>{trip.vehicle_id}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell className="font-medium">License Plate</TableCell>
                <TableCell data-testid={'license_plate'}>{trip.license_plate ? convertToDisplayLicensePlate(trip.license_plate) : ''}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell className="font-medium">Start Time</TableCell>
                <TableCell data-testid={'trip_start_time'}>{convertDateFormat(trip.trip_start_time)}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell className="font-medium">End Time</TableCell>
                <TableCell data-testid={'end_time'}>{convertDateFormat(trip.end_time)}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell className="font-medium">Occupancy</TableCell>
                <TableCell data-testid={'occupancy'}>{trip.occupancy}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell className="font-medium">In Corridor</TableCell>
                <TableCell data-testid={'in_corridor'}>{convertToYesNo(trip.in_corridor)}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell className="font-medium">Valid</TableCell>
                <TableCell data-testid={'valid'}>{convertToYesNo(trip.valid)}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell className="font-medium">Transponder ID</TableCell>
                <TableCell data-testid='transponder_id'>{trip.transponder_id}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell className="font-medium">Ambient Light Reverify</TableCell>
                <TableCell data-testid='ambient_light_reverify'>{trip.ambient_light_reverify}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell className="font-medium">Ambient Light Verify</TableCell>
                <TableCell data-testid='ambient_light_verify'>{trip.ambient_light_verify}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell className="font-medium">Attempts Reverify</TableCell>
                <TableCell data-testid='attempts_reverify'>{trip.attempts_reverify}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell className="font-medium">Passenger Camera Dwell Time</TableCell>
                <TableCell data-testid='passenger_camera_dwell_time'>{trip.passenger_camera_dwell_time}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell className="font-medium">Reverify Camera Dwell Time 1</TableCell>
                <TableCell data-testid='reverify_camera_dwell_time_1'>{trip.reverify_camera_dwell_time_1}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell className="font-medium">Reverify Camera Dwell Time 2</TableCell>
                <TableCell data-testid='reverify_camera_dwell_time_2'>{trip.reverify_camera_dwell_time_2}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell className="font-medium">Auto Close</TableCell>
                <TableCell data-testid='auto_close'>{convertToYesNo(trip.auto_close)}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell className="font-medium">Started Offline</TableCell>
                <TableCell data-testid='started_offline'>{convertToYesNo(trip.started_offline)}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell className="font-medium">Ended Offline</TableCell>
                <TableCell data-testid='ended_offline'>{convertToYesNo(trip.ended_offline)}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell className="font-medium">No GPS</TableCell>
                <TableCell data-testid='no_gps'>{convertToYesNo(trip.no_gps)}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell className="font-medium">Reported Time</TableCell>
                <TableCell data-testid='reported_time'>
                  {trip.reported_time &&
                    trip.reported_time !== 'NULL' &&
                    trip.reported_time !== null &&
                    !isNaN(new Date(trip.reported_time.replace(' ', 'T')).getTime())
                    ? convertDateFormat(trip.reported_time.replace(' ', 'T'))
                    : 'N/A'}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell className="font-medium">Multi Device Trip</TableCell>
                <TableCell data-testid='multi_device_trip'>{convertToYesNo(trip.multi_device_trip)}</TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </CardContent>
      </Card>
    </div>
  )
}

export default TripDetailComponent