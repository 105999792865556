import React, { createContext, useState, useContext, useCallback } from 'react';
import { get } from 'aws-amplify/api';
import { getValidAuthToken } from '../utils/auth';

const QuickSightContext = createContext<{
    embedUrl: string | null;
    loading: boolean;
    error: string | null;
    getQuickSightEmbedUrl: () => Promise<void>;
}>({ embedUrl: null, loading: false, error: null, getQuickSightEmbedUrl: async () => {} });

export const useQuickSightContext = () => useContext(QuickSightContext);

export const QuickSightProvider = ({ children }: { children: React.ReactNode }) => {
  const [embedUrl, setEmbedUrl] = useState<string | null>(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const getQuickSightEmbedUrl = useCallback(async () => {
    setLoading(true);
    setError(null);

    try {
      const authToken = await getValidAuthToken();
      const response = await get({
        apiName: 'tripQueryAPI',
        path: '/quicksight/v1/getembedurl',
        options: {
          headers: { Authorization: authToken || '' },
        }
      });

      const responseData = await response.response;

      // Ensure the response body exists and is a ReadableStream
      if (!responseData.body || !(responseData.body instanceof ReadableStream)) {
        throw new Error('Response body is not a ReadableStream.');
      }

      const reader = (responseData.body as ReadableStream<Uint8Array>).getReader();
      const decoder = new TextDecoder();
      let result = '';
      while (true) {
        const { done, value } = await reader.read();
        if (done) break;
        result += decoder.decode(value || new Uint8Array(), { stream: true });
      }
      
      const body = JSON.parse(result);
      
      if (body && body.embedUrl) {
        setEmbedUrl(body.embedUrl);
      } else {
        throw new Error('embedUrl not found in response');
      }
    } catch (error) {
      console.error('Error fetching QuickSight embed URL:', error);
      setError(error instanceof Error ? error.message : 'Unknown error');
    } finally {
      setLoading(false);
    }
  }, []);

  return (
    <QuickSightContext.Provider value={{ embedUrl, loading, error, getQuickSightEmbedUrl }}>
      {children}
    </QuickSightContext.Provider>
  );
};
