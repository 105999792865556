export const convertToYesNo = (value: any): string => {
    if (
        value === null ||
        value === undefined ||
        value === 0 ||
        value === '0' ||
        value === false ||
        value === '' ||
        (typeof value === 'string' && value.trim().toLowerCase() === 'false') ||
        (typeof value === 'string' && value.trim().toLowerCase() === 'no') ||
        (typeof value === 'string' && ['null', ''].includes(value.trim().toLowerCase())) ||
        Number.isNaN(value)
    ) {
        return 'No';
    }
    return 'Yes';
};