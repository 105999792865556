import { ConsoleLogger } from 'aws-amplify/utils';
import { post } from 'aws-amplify/api';
import { getValidAuthToken } from '../utils/auth';

const logger = new ConsoleLogger('Log');

export const logError = (message: string, error: any) => {
  logger.error(message, error);
};

export const logInfo = (message: string) => {
  logger.info(message);
};

export const logWarn = (message: string) => {
  logger.warn(message);
};

export const logDebug = (message: string) => {
  logger.debug(message);
};

class LoggerService {
    static instance: LoggerService | null = null;

    static getInstance() {
        if (LoggerService.instance == null) {
            LoggerService.instance = new LoggerService();
        }

        return LoggerService.instance;
    }

    async log(errorOrMessage: string | Error, additionalInfo: Record<string, any> = {}) {
        let errorMessage, stackTrace;

        const error = new Error();
        const stack = error.stack || 'No stack trace available';

        if (typeof errorOrMessage === 'string') {
            errorMessage = errorOrMessage;
            stackTrace = stack;
        } else {
            errorMessage = errorOrMessage.message || 'No error message provided';
            stackTrace = errorOrMessage.stack || stack;
        }

        logDebug(errorMessage);

        const payload = {
            errorMessage,
            stackTrace,
            additionalInfo,
            timestamp: new Date().toISOString(),
        };

        try {
            const authToken = await getValidAuthToken();
            if (!authToken) {
                console.warn('No valid auth token payload');
                return;
            }

            await post({
                apiName: 'tripQueryAPI',
                path: '/system/v1/createlog',
                options: {
                    headers: { 
                        Authorization: authToken,
                    },
                    body: {
                        message: payload
                    }
                }
            });
        } catch (apiError) {
            console.error('Failed to log:', apiError);
        }
    }
}

const loggerService = new LoggerService()
export default loggerService;
