import { useCallback, useEffect, useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Card, CardContent, CardHeader, CardTitle } from './ui/card'
import { Button } from './ui/button'
import { Input } from './ui/input'
import { RefreshCw, Download } from 'lucide-react'
import { useUserContext } from '../contexts/UserContext'
import DataTable from './common/DataTable'
import { generateColumns, filterColumns } from '../utils/tableUtils'
import { UserProfile, UserQueryState } from '../types/user'
import { Spinner } from './ui/spinner'
import { usePersistedState } from '../hooks/usePersistedState'
import { AlertView } from './common/Alert'
import { ColumnDef } from '@tanstack/react-table'
import { useCSVDownload } from '../hooks/useCSVDownload'
import { DataTableSkeleton } from './TableSkeleton'
import { useTrackNavigation } from '../hooks/useTrackNavigation'
import { validateEmail } from '../utils/sanitizeInput'

const ProfileComponent = (): JSX.Element => {
    const navigate = useNavigate()
    const { data: users, loading, error, fetchData: fetchUsers, clearData: clearUsers } = useUserContext()
    const { isDownloading, handleDownload } = useCSVDownload<UserProfile>()
    const [phoneError, setPhoneError] = useState<string>('')
    const [emailError, setEmailError] = useState<string>('')

    useTrackNavigation()

    useEffect(() => {
        setQueryState(prev => ({ ...prev, filteredUsers: users }))
    }, [users])

    const getDefaultState = (): UserQueryState => ({
        searchTerm: '',
        currentPage: 1,
        itemsPerPage: 10,
        phoneNumber: '',
        email: '',
        users: [],
        filteredUsers: users,
        tableFilter: '',
    })

    const [queryState, setQueryState] = usePersistedState<UserQueryState>({
        storageKey: 'UserQueryState',
        defaultValue: getDefaultState()
    })

    const setCurrentPage = useCallback((currentPage: number) =>
        setQueryState(prev => ({ ...prev, currentPage })),
        [setQueryState]
    )

    const setItemsPerPage = useCallback((itemsPerPage: number) =>
        setQueryState(prev => ({ ...prev, itemsPerPage, currentPage: 1 })),
        [setQueryState]
    )

    const setTableFilter = useCallback((tableFilter: string) => {
        setQueryState(prev => ({ ...prev, tableFilter }))
    }, [setQueryState])

    const handlePhoneNumberChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
        const value = e.target.value.replace(/\D/g, '')
        if (emailError) {
            setEmailError('')
        }
        
        if (value.length > 10) {
            setPhoneError('Phone number cannot exceed 10 digits')
        } else if (value.length < 10) {
            setPhoneError('Phone number must be 10 digits')
        } else {
            setPhoneError('')
        }
        
        setQueryState(prev => ({ ...prev, phoneNumber: value.slice(0, 10), email: '' }))
    }

    const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
        const value = e.target.value
        if (phoneError) {
            setPhoneError('')
        }
        
        if (value && !validateEmail(value)) {
            setEmailError('Please enter a valid email address')
        } else {
            setEmailError('')
        }

        setQueryState(prev => ({ ...prev, email: value, phoneNumber: '' }))
    }

    const resetToDefaults = () => {
        setQueryState(getDefaultState())
        clearUsers()
    }

    const handleFindPhoneNumberClick = useCallback(() => {
        if (!queryState.phoneNumber) {
            setPhoneError('Please enter a valid phone number')
            return
        }

        clearUsers()
        fetchUsers({ phoneNumber: queryState.phoneNumber, email: queryState.email })
        setQueryState({
            ...queryState,
            tableFilter: '',
            currentPage: 1,
        })
    }, [fetchUsers, clearUsers, queryState.phoneNumber])

    const handleFindEmailClick = useCallback(() => {
        if (!queryState.email) {
            setEmailError('Please enter a valid email address')
            return
        }

        clearUsers()
        fetchUsers({ phoneNumber: queryState.phoneNumber, email: queryState.email })
        setQueryState({
            ...queryState,
            tableFilter: '',
            currentPage: 1,
        })
    }, [fetchUsers, clearUsers, queryState.email])

    const handleDataChange = useCallback((data: UserProfile[]) => {
        setQueryState(prev => ({ ...prev, filteredUsers: data }))
    }, [setQueryState])

    const handleDownloadCSV = () => {
        if (!queryState.filteredUsers.length) return
        handleDownload({
            getData: () => queryState.filteredUsers,
            filename: 'user_data.csv',
            sanitizeData: (users) => users.map(({ user_id, email, phone_number, ...rest }) => rest)
        })
    }

    const columns: ColumnDef<UserProfile, any>[] = useMemo(() => {
        const defaultColumns: ColumnDef<UserProfile, any>[] = [
            { accessorKey: 'user_uuid', header: 'UUID' },
            { accessorKey: 'created_on', header: 'Created On' },
            { accessorKey: 'modified_on', header: 'Modified On' },
            { accessorKey: 'in_corridor', header: 'In Corridor' },
            { accessorKey: 'completed_trips', header: 'Completed Trips' },
            { accessorKey: 'violations', header: 'Violations' },
        ]

        if (!users.length) return defaultColumns

        const allColumns = generateColumns<UserProfile>(users)
        const excludeFields = ['user_id', '_internal_id', 'created_at', 'updated_at', 'signed_up_next_round', 'confirmed', 'inactive', 'phone_number', 'email']
        return filterColumns(allColumns, excludeFields)
    }, [users])

    const paginationState = {
        currentPage: queryState.currentPage,
        itemsPerPage: queryState.itemsPerPage,
        onPageChange: setCurrentPage,
        onItemsPerPageChange: setItemsPerPage
    }

    const tableFilterState = {
        tableFilter: queryState.tableFilter,
        onTableFilterChange: setTableFilter
    }

    return (
        <div className="p-4">
            <div className="flex flex-col md:flex-row gap-5 mb-4">
                <Card className="flex-1 md:max-w-2xl bg-white">
                    <CardHeader>
                        <CardTitle>User Profiles</CardTitle>
                    </CardHeader>
                    <CardContent>
                        <div className="flex flex-col md:flex-row gap-4">
                            {/* Phone Number Group */}
                            <div className="flex flex-col sm:flex-row gap-4 flex-1">
                                <div className="flex-1">
                                    <label htmlFor="phoneNumber" className="text-sm font-medium">Phone Number</label>
                                    <Input
                                        id="phoneNumber"
                                        type="tel"
                                        placeholder="Enter phone number"
                                        value={queryState.phoneNumber}
                                        onChange={handlePhoneNumberChange}
                                        className={phoneError ? 'border-red-500' : ''}
                                        data-testid="phone-number-input"
                                    />
                                    {phoneError && (
                                        <span className="text-sm text-red-500 mt-1">{phoneError}</span>
                                    )}
                                </div>
                                <Button
                                    onClick={handleFindPhoneNumberClick}
                                    disabled={!queryState.phoneNumber || !!phoneError}
                                    className="bg-green-600 hover:bg-green-700 text-white h-10 mt-auto"
                                    data-testid="find-phone-number-button"
                                >
                                    Find
                                </Button>
                            </div>

                            {/* Email Group */}
                            <div className="flex flex-col sm:flex-row gap-4 flex-1">
                                <div className="flex-1">
                                    <label htmlFor="email" className="text-sm font-medium">Email</label>
                                    <Input
                                        id="email"
                                        type="email"
                                        placeholder="Enter email"
                                        value={queryState.email}
                                        onChange={handleEmailChange}
                                        className={emailError ? 'border-red-500' : ''}
                                        data-testid="email-input"
                                    />
                                    {emailError && (
                                        <span className="text-sm text-red-500 mt-1">{emailError}</span>
                                    )}
                                </div>
                                <Button
                                    onClick={handleFindEmailClick}
                                    disabled={!queryState.email || !!emailError}
                                    className="bg-green-600 hover:bg-green-700 text-white h-10 mt-auto"
                                    data-testid="find-email-button"
                                >
                                    Find
                                </Button>
                            </div>
                        </div>
                    </CardContent>
                </Card>

                <div className="flex flex-col gap-2 md:justify-end self-end">
                    <Button
                        onClick={resetToDefaults}
                        variant="outline"
                        className="w-full md:w-auto bg-secondary hover:bg-secondary/90 text-secondary-foreground bg-green-600 text-white hover:bg-green-700"
                    >
                        <RefreshCw className="w-4 h-4 mr-2" />
                        Reset
                    </Button>
                    <Button
                        onClick={handleDownloadCSV}
                        variant="outline"
                        className="w-full md:w-auto bg-primary hover:bg-primary/90 text-primary-foreground bg-green-600 text-white hover:bg-green-700"
                        disabled={isDownloading || users.length === 0}
                    >
                        {isDownloading ? (
                            <>
                                <Spinner size="small" className="mr-2" />
                                Downloading...
                            </>
                        ) : (
                            <>
                                <Download className="w-4 h-4 mr-2" />
                                Download CSV
                            </>
                        )}
                    </Button>
                </div>
            </div>

            {error ? (
                <AlertView
                    message="An error has occurred"
                    onAction={queryState.phoneNumber || queryState.email ? handleFindPhoneNumberClick : handleFindEmailClick}
                    buttonTitle="Retry"
                />
            ) : loading ? (
                <DataTableSkeleton rows={1} columns={6} />
            ) : (
                <DataTable
                    data={users}
                    columns={columns}
                    onRowClick={(user: UserProfile) => navigate(`/userProfiles/${user.user_uuid}`)}
                    paginationState={paginationState}
                    onDataChange={handleDataChange}
                    tableFilterState={tableFilterState}
                />
            )}
        </div>
    )
}

export default ProfileComponent