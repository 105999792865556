/*
  This file is used to store the state of the users and the functions to fetch the users.
  The users are stored in the state and the functions to fetch the users are stored in the context.
  It prevents the need to fetch the users again when the user navigates back to the page.
*/
import { UserProfile, UserQueryParams } from '../types/user';
import { createDataContext } from './Context';

const parseUsersResponse = (response: UserProfile[]): UserProfile[] => {
  return Array.isArray(response) ? response : []; 
};

export const { useDataContext: useUserContext, DataProvider: UserProvider } = createDataContext<UserProfile, UserQueryParams>(
  'tripQueryAPI',
  '/users/v1/getusers',
  parseUsersResponse
);
