import { createDataContext } from './Context';
import { UserViolation, ViolationQueryParams } from '../types/Violation';

const parseResponse = (response: UserViolation[]): UserViolation[] => {
  return Array.isArray(response) ? response : []; 
};

export const { useDataContext: useViolationContext, DataProvider: ViolationProvider } = createDataContext<UserViolation, ViolationQueryParams>(
  'tripQueryAPI',
  '/users/v1/getviolations',
  parseResponse
);