export const validateEmail = (email: string): boolean => {
    // RFC 5322 standards
    const emailRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;
    
    if (!emailRegex.test(email)) {
        return false;
    }

    try {
        // Use built-in URL parser as additional validation
        const url = new URL(`mailto:${email}`);
        return url.protocol === "mailto:";
    } catch {
        return false;
    }
};

export const sanitize = (value: string): string => {
    return value.replace(/[^a-zA-Z0-9._@-]/g, '');
};