import { useApi } from './ApiContext'
import { Trip, TripQueryParams, TripCount } from '../types/trip'

export const useTripQuery = (params: TripQueryParams, enabled: boolean) => {
  const { useApiQuery } = useApi()
  return useApiQuery<Trip[]>('tripQueryAPI', '/trips/v2/gettrips', params as unknown as Record<string, string>, enabled)
}

export const useTripCountQuery = (params: TripQueryParams, enabled: boolean) => {
  const { useApiQuery } = useApi()
  return useApiQuery<TripCount[]>('tripQueryAPI', '/trips/v1/gettripcount', params as unknown as Record<string, string>, enabled)
}

export const useDownloadableTripsQuery = (params: TripQueryParams, enabled: boolean) => {
  const { useApiQuery } = useApi()
  return useApiQuery<Trip[]>('tripQueryAPI', '/trips/v1/getdownloadabletrips', params as unknown as Record<string, string>, enabled)
}