import { Fragment } from "react";
import {useLocation } from "react-router-dom";
import { ChevronRight, ChevronDown } from "lucide-react";
import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  BreadcrumbList,
  BreadcrumbSeparator,
} from "../components/ui/breadcrumb";
import {
    DropdownMenu,
    DropdownMenuContent,
    DropdownMenuItem,
    DropdownMenuTrigger,
  } from "../components/ui/dropdown-menu"
import { useNavigate } from "react-router-dom";
import { Button } from "./ui/button";

export const DynamicBreadcrumb = (): JSX.Element => {
  const location = useLocation();
  const navigate = useNavigate();
  // Split the current path into segments
  const pathSegments = location.pathname.split("/").filter(Boolean);

  // Generate breadcrumb items
  const breadcrumbs = pathSegments.map((segment, index) => {
    // Build the path up to this segment
    const path = `/${pathSegments.slice(0, index + 1).join("/")}`;
    // Map segment to human-readable labels
    const label = segment.charAt(0).toUpperCase() + segment.slice(1);
    return { path, label };
  });

  return (
    <Breadcrumb className="pl-10">
      <BreadcrumbList>
        {/* Home Link */}
        <BreadcrumbItem>
          <BreadcrumbLink href="/" className="text-green-600">Home</BreadcrumbLink>
        </BreadcrumbItem>

        {/* Separator */}
        {breadcrumbs.length > 0 && (
          <BreadcrumbSeparator>
            <ChevronRight />
          </BreadcrumbSeparator>
        )}

        {/* Dynamic Breadcrumb Items */}
        {breadcrumbs.map((crumb, index) => (
          <Fragment key={crumb.path}>
            <BreadcrumbItem>
              {index === 0 ? (
                // Dropdown menu for first segment
                <DropdownMenu >
                  <DropdownMenuTrigger className="flex items-center gap-1 text-green-600">
                    {crumb.label}
                    <ChevronDown className="h-4 w-4" />
                  </DropdownMenuTrigger>
                  <DropdownMenuContent align="start" className="bg-background">
                    <DropdownMenuItem asChild>
                        <Button 
                            onClick={() => navigate('/trips')} 
                            variant="ghost"
                            className="text-green-600 hover:bg-slate-100 cursor-pointer"
                        >
                            Trips
                        </Button>
                    </DropdownMenuItem>
                    <DropdownMenuItem asChild>
                        <Button 
                            onClick={() => navigate('/userProfiles')} 
                            variant="ghost"
                            className="text-green-600 hover:bg-slate-100 cursor-pointer"
                        >
                            User Profiles
                        </Button>
                    </DropdownMenuItem>
                    <DropdownMenuItem asChild>
                        <Button 
                            onClick={() => navigate('/violations')} 
                            variant="ghost"
                            className="text-green-600 hover:bg-slate-100 cursor-pointer"
                        >
                            Violations
                        </Button>
                    </DropdownMenuItem>
                    <DropdownMenuItem asChild>
                        <Button 
                            onClick={() => navigate('/analytics')} 
                            variant="ghost"
                            className="text-green-600 hover:bg-slate-100 cursor-pointer"
                        >
                            Analytics
                        </Button>
                    </DropdownMenuItem>
                  </DropdownMenuContent>
                </DropdownMenu>
              ) : index < breadcrumbs.length - 1 ? (
                <BreadcrumbLink href={crumb.path} className="text-green-600">{crumb.label}</BreadcrumbLink>
              ) : (
                <span className="text-green-600">{crumb.label}</span>
              )}
            </BreadcrumbItem>

            {/* Separator (except for the last item) */}
            {index < breadcrumbs.length - 1 && (
              <BreadcrumbSeparator>
                <ChevronRight />
              </BreadcrumbSeparator>
            )}
          </Fragment>
        ))}
      </BreadcrumbList>
    </Breadcrumb>
  );
}