/*
  This file is used to store the state of the trips and the functions to fetch the trips.
  The trips are stored in the state and the functions to fetch the trips are stored in the context.
  It prevents the need to fetch the trips again when the user navigates back to the page.
*/
import { createDataContext } from './Context';
import { Trip, TripQueryParams } from '../types/trip';

const parseTripsResponse = (response: Trip[]): Trip[] => {
  return Array.isArray(response) ? response : []; 
};

export const { useDataContext: useTripContext, DataProvider: TripProvider } = createDataContext<Trip, TripQueryParams>(
  'tripQueryAPI',
  '/trips/v1/gettrips',
  parseTripsResponse
);